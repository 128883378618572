import styles from './Warscroll.module.scss';
import Abilities from "./Abilities";
import {Card, CardContent, CardHeader, CardMedia, Container, Typography} from "@mui/material";
import Weapons from "./Weapons";
import {replaceNewLines} from "./Warscrolls";


const Warscroll = ({scroll}) => {

    return <Container sx={{bgcolor: 'info.main', margin: "5px", borderRadius: 5, border: 1}} key={scroll.id}>
        <div className={styles.top}>
            {scroll.image ? <div className={styles.left + " " + styles.img}>
                <Card>
                    <CardMedia
                        component="img"
                        height={"300"}
                        image={scroll.image}
                        alt={scroll.name}
                    />
                </Card>
            </div> : null}
            <div className={styles.right}>
                <div className={styles.banner}>
                    <h1>{scroll.name}</h1>
                </div>
                <div className={styles.top}>
                    {scroll.move ? <Card sx={{margin: "5px"}}>
                        <CardContent>
                            <Typography variant="h4" align={"center"} component="div">
                                {scroll.move}
                            </Typography>
                        </CardContent>
                        <CardHeader title={"Move"}/>
                    </Card> : null}
                    {scroll.health ?
                        <Card sx={{margin: "5px"}}>
                            <CardContent> <Typography variant="h4" align={"center"}
                                                      component="div">{scroll.health}</Typography></CardContent>
                            <CardHeader title="Health"/>
                        </Card> : null}
                    {scroll.save ? <Card sx={{margin: "5px"}}>
                        <CardContent> <Typography variant="h4" align={"center"}
                                                  component="div">{scroll.save}</Typography></CardContent>
                        <CardHeader title="Save"/>
                    </Card> : null}
                    {scroll.ward ? <Card sx={{margin: "5px"}}>
                        <CardContent> <Typography variant="h4" align={"center"}
                                                  component="div">{scroll.ward}</Typography></CardContent>
                        <CardHeader title="Ward"/>
                    </Card> : null}
                    {scroll.wizardLevel ?
                        <Card sx={{margin: "5px"}}>
                            <CardContent> <Typography variant="h4" align={"center"}
                                                      component="div">{scroll.wizardLevel}</Typography></CardContent>
                            <CardHeader title="Wizard"/>
                        </Card> : null}
                    {scroll.priestLevel ?
                        <Card sx={{margin: "5px"}}>
                            <CardContent> <Typography variant="h4" align={"center"}
                                                      component="div">{scroll.priestLevel}</Typography></CardContent>
                            <CardHeader title="Priest"/>
                        </Card> : null}
                    {scroll.control ?
                        <Card sx={{margin: "5px"}}>
                            <CardContent> <Typography variant="h4" align={"center"}
                                                      component="div">{scroll.control}</Typography></CardContent>
                            <CardHeader title={scroll.keywords.includes("Manifestation")?"Banishment":"Control"}/>
                        </Card> : null}
                </div>
                <div className={styles.right + " " + styles.weapons}>
                    <Weapons weapons={scroll.weapons.filter(weapon => weapon.type === "ranged")}
                             type={"Ranged Weapons"}/>
                    <Weapons weapons={scroll.weapons.filter(weapon => weapon.type === "melee")} type={"Melee Weapons"}/>
                </div>
            </div>
        </div>

        <div className={styles.main}>
            <Abilities abilities={scroll.abilities} wargear={
                scroll.wargearOptionsText ? <Card className={styles.wargear}>
                        <CardHeader title={"Wargear"}/>
                        <CardContent>{replaceNewLines(scroll.wargearOptionsText)}</CardContent>
                    </Card> : null}/>

            <div>
                {scroll.keywords.length > 0 ?
                    <Container sx={{borderColor: 'secondary.main', border: 1}}>
                        <div><strong>KEYWORDS </strong></div>
                        <div className={styles.words}> {scroll.keywords.join(", ")}</div>
                    </Container> : null}
                <div>
                    {scroll.points ? <div>Points: {scroll.points}</div> : null}
                    {scroll.modelCount ? <div>Model Count: {scroll.modelCount}</div> : null}
                    {scroll.baseSize ? <div>Basesize : {scroll.baseSize}</div> : null}
                    {scroll.notes ? <div>Notes: {scroll.notes}</div> : null}
                </div>
            </div>
        </div>

    </Container>;
}

export default Warscroll