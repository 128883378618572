import './index.css';
import * as React from "react";
import {createRoot} from "react-dom/client";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import warscrollData from './warscrollData.json'
import abilityData from './abilities.json'
import Warscrolls from "./components/Warscrolls";
import Arena from "./components/Arena";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Warscrolls warscrollData={warscrollData.sort((a, b) => a.name.localeCompare(b.name))}
                             abilityData={abilityData}/>,
    }, {
        path: "/arena",
        element: <Arena warscrollData={warscrollData.sort((a, b) => a.name.localeCompare(b.name))}/>,
    }
]);

createRoot(document.getElementById("root")).render(
    <RouterProvider router={router}/>
);
